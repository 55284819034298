<script> 
import { mapState } from 'vuex';  
import pc_query from '@/components/cjQuery/pc_query/pc_query'
import h5_query from '@/components/cjQuery/h5_query/h5_query'

export default {
	components:{},
	name: 'index',
	data() {
		return { 
		};
	},
	components: {
		[pc_query.name]: pc_query,
		[h5_query.name]: h5_query
	},
	watch: { 
		
	},
	render(createElement) {
	  console.log('1,',this.isPc)
	  if (this.isPc) {
	    // return createElement('pc_query') 
		this.$router.push({ name: 'testLogIn', params: { type: 'result' } })
	  }
	  return createElement('h5_query')
	},
	created() {
		 
	},
	mounted() {},
	computed: {
		isPc() {
		  return this.$store.state.isPc;
		},
	},
	computed: {
	  ...mapState(['isPc'])
	},
};
</script>

<style lang="scss" scoped="scoped"> 
</style>
