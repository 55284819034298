<template>
	<div class="wrapper" v-show='viewShow'>
		<section class="wrapper_top">
			<img :src="require('@/assets/image/fh.png')" alt="" @click="fh()" /> 
		</section>
		<section class="wrapper_centent">
			<div class="logo">
				<img :src="require('@/assets/image/logo2.png')" alt="" /> 
			</div>
			
			<div class="titleValue">
				職業普通話水平測試<br />在線考試系統
			</div>
			
			<div class="nav">
				<div :class="select==0?'select':''" @click="navBut(0)">成績查詢</div>
				<div :class="select==1?'select':''" @click="navBut(1)">證書查詢</div> 
			</div>
			
			<div class="uls" v-if="select==0">
				<div class="lis"> 
					<input type="text" v-model="from.testCode" placeholder="請輸入考生號" @blur="onBlurInput()"/>
				</div>
				<div class="lis">
					<country class="country" :countryCode.sync="from.countryCode"></country>
					<input type="text" v-model="from.phoneNumber " placeholder="請輸入手機號" @input="from.phoneNumber = Number($event.target.value.replace(/\D+/, ''))" @blur="onBlurInput()"/>
				</div>
				<div class="lis">
					<input type="text" v-model="from.phoneCode" placeholder="請輸入驗證碼" />
					<span @click="CodeBln ? clickNumberCodeFnc() : ''">{{ NumberCodeText }}</span>
				</div>
			</div>
			
			<div class="uls" v-if="select==1">
				<div class="lis"> 
					<input type="text" v-model="from2.name" placeholder="請輸入姓名" />
				</div>
			 <div class="lis">
				<input type="text" v-model="from2.certificateNo" placeholder="請輸入證書編號" />
			 </div>
			</div>
			
			<div class="fromBut" @click="myQuery()" v-if="select==0">成績查詢</div>
			<div class="fromBut" @click="myQuery2()" v-if="select==1">證書查詢</div>
			 
			<div class="bz" v-if="select==0">
				測試成績一般在測試後7個工作日後可查詢；請保證證件照片為本人，考試現場將會核對信息，如信息不準確此次預約會作廢處理。
			</div>
		</section>
	</div>
</template>

<script>
	import { mapState } from 'vuex';  
import country from '@/components/reservationForm/country.vue';
import api from '@/api/index';
export default {
	name: 'h5_query',
	data() {
		return {
			from:{
				testCode: null, //姓名
				phoneNumber: null, //手機號
				countryCode: Number(852), //區號
				phoneCode: null, //驗證碼 
			},
			NumberCodeText: '獲取驗證碼',
			CodeBln: true,
			timeOut: null,
			from2:{
				name:null,
				certificateNo:null
			},
			select:0, 
		};
	},
	watch: {},
	components: { country },
	created() {},
	mounted() {},
	computed: {
		 ...mapState(['isPc','viewShow']),
		viewShow(){
		  return this.$store.state.viewShow;
		}
	},
	methods: {
		fh() {
			history.go(-1); //返回首頁 
		}, 
		// 手機
		clickNumberCodeFnc() {
			this.$toast.loading({
				forbidClick: true,
				overlay: true,
				duration: 0
			});
			const { phoneNumber, countryCode } = this.from;
			let regPhone = countryCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/;
		
			if (!phoneNumber) {
				this.$toast('請輸入正確的手機號');
				return false;
			}
		
			if (!regPhone.test(phoneNumber)) {
				this.$toast('請檢查是否輸入');
				return false;
			}
		
			clearInterval(this.timeOut);
			this.CodeBln = false;
			let index = 60;
			let phoneValue = countryCode === 86 ? phoneNumber : '+' + countryCode + '-' + phoneNumber;
			const phoneList = [];
			phoneList.push('+' + countryCode + '-' + phoneNumber);
		
			let params = {
				appType: 12,
				verifyType: 9,
				phoneList,
				smsTemplateType: 10,
				signType: 1
			};
			console.log('打印傳參:::', params);
		
			api.sendVerificationCode(params)
				.then(res => {
					this.$toast.clear();
					console.log(res);
					if (res.code == 0) {
						this.timeOut = setInterval(() => {
							index--;
							if (index <= 0) {
								index = 60;
								this.CodeBln = true;
								this.NumberCodeText = '獲取驗證碼';
								clearInterval(this.timeOut);
							} else {
								this.NumberCodeText = `${index}s`;
							}
						}, 1000);
					} else {
						this.$toast(res.msg)
						this.CodeBln = true;
					}
				})
				.catch(error => {
					this.$toast.clear();
					this.$toast('獲取失敗,請重新獲取');
					this.NumberCodeText = '重新獲取';
					this.CodeBln = true;
				});
		},
		// 處理ios軟鍵盤彈出頁面被頂上去兼容問題
		onBlurInput() {
			window.scroll(0, 0);
		},
		login(){
			this.$router.push('/reservationInquireList');
		},
		navBut(e){
			this.select = e
		},
		
		myQuery(){
			// 成績
			// this.$router.push('/certificate');
			const { testCode, phoneNumber, phoneCode,countryCode } = this.from
			let regPhone = countryCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/
			if (!testCode || !testCode.trim()) {
				this.$toast('請輸入考生號')
				return false
			} else if (!phoneNumber) {
				this.$toast('請輸入手機號碼')
				return false
			} else if (!regPhone.test(phoneNumber)) {
				this.$toast('手機號碼有誤，請重新輸入')
				return false
			} else if (!phoneCode) {
				this.$toast('驗證碼不能為空')
				return false
			}
			
			const params = {
				prefix: countryCode,
				testCode,
				phone:phoneNumber,
				verifyCode:phoneCode
			}
			
			this.$toast.loading({
				message: '查詢中...',
				forbidClick: true,
				overlay: true,
				duration: 0
			}) 
				
			console.log('params::',params)
			api.getQuerySore(params)
			  .then((res) => {
				console.log(res, 'res')
				this.$toast.clear()
				if (res.code == 0) {
					if(res.data == null){
						this.$toast('查詢不到成績,請測試後再來查詢吧')
					}else{
						window.sessionStorage.setItem('QuerySore',JSON.stringify(res.data))
						this.$router.push({
							name: 'certificate',
							params: { data: res.data }
						})
					} 
				} else {
				  this.$toast(res.msg)
				}
			  })
			  .catch((err) => {
				this.$toast.clear()
			  })
			
		},
		myQuery2(){
			if (!this.from2.name) {
				this.$toast('請輸入姓名')
				return false
			} else if (!this.from2.certificateNo) {
				this.$toast('請輸入證書編號')
				return false
			}
			
			// 證書
			this.$toast.loading({
				message: '查詢中...',
				forbidClick: true,
				overlay: true,
				duration: 0
			}) 
			
			// setTimeout(()=>{
			// 	this.$toast.clear();
			// 	this.$toast('正在調試')
			// })
			
			let params = null; 
			api.getCertificate(params,this.from2.name,this.from2.certificateNo)
			  .then((res) => {
				console.log(res, '成绩')
				this.$toast.clear()
				if (res.code == 0) { 
					this.$router.push({ path: '/achievement?url='+JSON.stringify(res.data)}) 
				} else {
				  this.$toast(res.msg)
				}
			  })
			  .catch((err) => {
				this.$toast.clear()
			  })
			  
			
			
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/css/index.scss';
// 改寫部分vant  Dialog彈出框
.van-dialog {
	width: px2rem(640);
	font-size: px2rem(32);
}
.van-dialog__message {
	padding: px2rem(52) px2rem(48);
	font-size: px2rem(28);
	line-height: px2rem(40);
}
.van-dialog__header {
	padding-top: px2rem(52);
	line-height: px2rem(58);
}
.van-dialog__cancel,
.van-dialog__confirm {
	height: px2rem(96);
}
.van-button {
	font-size: px2rem(32);
}
.van-hairline--top::after {
	border-top-width: px2rem(4);
}
.van-hairline--left::after {
	border-left-width: px2rem(4);
}
.van-picker__toolbar{
	height: px2rem(100) !important;
}
.van-loading__spinner {
	width: px2rem(100) !important;
	height: px2rem(100) !important;
}
.van-toast {
	width: auto;
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	// font-size: px2rem(28);
	overflow-x: hidden;
	// background: linear-gradient(309deg, #132bb0 0%, #1934cb 100%);
	background: url('~@/assets/image/csbg.png') no-repeat;
	background-size: 100%;
	padding: px2rem(60) px2rem(32);
	box-sizing: border-box;
	
	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		// background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #000000;
	
		img {
			width: px2rem(56);
			height: px2rem(56);
			position: absolute;
			left: px2rem(32);
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	
	.wrapper_centent{
		width: 100%;
		padding: px2rem(32);
		padding-top: px2rem(160);
		box-sizing: border-box;
		
		.logo{
			width: px2rem(160);
			height:px2rem(160);
			display: flex;
			align-items: center;
			justify-content: center;
			background: #FFFFFF;
			box-shadow: 0px px2rem(4) px2rem(40) 0px rgba(0,0,0,0.12);
			border-radius: px2rem(32); 
			
			img{
				width: px2rem(96);
				height: px2rem(120);
			}
		}
		
		.titleValue{
			font-size: px2rem(48);
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #FFFFFF;
			line-height: px2rem(77);
			text-align: left;
			margin-top: px2rem(50);
		}
		
		.nav{
			padding-top: px2rem(72);
			width:100%;
			height: px2rem(56);
			display: flex;
			
			div{
				font-size: px2rem(32);
				font-family: PingFang SC-Semibold, PingFang SC; 
				color: #FFFFFF;
				line-height: px2rem(38);
				margin-right: px2rem(48); 
			}
			
			.select{
				position: relative;
				color: #FFE600 !important;
				font-weight: 600;
			}
			
			.select:after {
			  content: ' ';
			  width: px2rem(48);
			  height: px2rem(4);
			  background: #FFE600;
			  position: absolute;
			  left: 0; 
			  bottom: 0%;
			  margin: auto;
			}
		}
		
		
		.uls{
			width: 100%; 
			padding-top: px2rem(4);
			
			.lis{
				width: 100%;
				// height: px2rem(120);
				border-bottom: px2rem(1) solid #667DFF; 
				color: #A9B6FF;
				display: flex;
				align-items: center;
				margin-top: px2rem(32);
				padding-top: px2rem(24);
				padding-bottom: px2rem(24);
				box-sizing: border-box;
				
				.country {
					width: px2rem(100);
					position: relative;
					text-align: center;
					padding-right: px2rem(32);
				}
				
				// .country::after {
				// 	content: ' ';
				// 	width: px2rem(2);
				// 	height: 100%;
				// 	background: #f2f2f2;
				// 	position: absolute;
				// 	top: 0;
				// 	right: 0;
				// 	bottom: 0%;
				// 	margin: auto;
				// }
				
				span{
					font-size: px2rem(28);
					font-family:PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					color: #FFE600; 
					white-space: nowrap;
				}
				
				input {
					border: none;
					padding: 0px;
					text-align: left;
					width: 100%;
					height: 100%;
					background: none; 
					font-size: px2rem(28);
				}
				
				input:focus {
					outline: none !important;
				}
				input::-webkit-input-placeholder {
					color: #A9B6FF;
				}
				/* 使用webkit內核的瀏覽器 */
				input:-moz-placeholder {
					color: #A9B6FF;
				}
				/* Firefox版本4-18 */
				input::-moz-placeholder {
					color: #A9B6FF;
				}
				/* Firefox版本19+ */
				input:-ms-input-placeholder {
					color: #A9B6FF;
				} /* IE瀏覽器 */
			}
		}
	
		.fromBut {
			width: 100%;  
			box-sizing: border-box;
			height: px2rem(112);
			line-height: px2rem(112); 
			background: #FFFFFF;
			border-radius: px2rem(108);
			margin: auto;
			margin-top: px2rem(96); 
			font-size: px2rem(36);
			font-family: PingFang SC-Semibold, PingFang SC !important;
			font-weight: 600; 
			color: #333333;
			box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(18, 42, 176, 0.3);
		}
		
		.bz{
			margin-top: px2rem(48); 
			font-size:  px2rem(24); 
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height:  px2rem(38);
			text-align: left;
		}
	}
}
</style>
